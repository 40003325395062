import App from '@/App.vue'

import {createApp} from "vue";
import {createRouter,createWebHashHistory} from "vue-router";
import {createPinia} from 'pinia'

import '@/assets/css/buttons.css'
import '@/assets/css/colors.css'
import '@/assets/css/bridge_main.css'

// set routes
const routes=[
  // common
  {path:'/',component:()=>import('@/components/main/MainMenu.vue')},
  {path:'/404',component:()=>import('@/components/main/NotFoundPage.vue')},
  {path:'/about',component:()=>import('@/components/main/AboutPage.vue')},
  // functions
  {path:'/count_hcp',component:()=>import('@/components/functions/CountHcp.vue')},
  {path:'/opening_bid',component:()=>import('@/components/functions/OpeningBid.vue')},
  // fail
  {path:'/:pathMatch(.*)*',redirect:'/404'}
]
const router=createRouter({
  history:createWebHashHistory(),
  routes
})
// create pinia store
const pinia=createPinia()

// create app
const app=createApp(App)
app.use(router)
app.use(pinia)
app.mount('#app')
