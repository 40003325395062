// standard texts
export const standard_texts={
  // language constants
  NOT_IMPLEMENTED:
    '~EN:to be implemented'+
    '~DE:noch nicht implementiert'+
    '~FR:pas encore implémenté'+
    '~ES:por implementar'+
    '~NL:nog niet geimplementeerd~~',

  // numbers
  ONE:'~EN:one~DE:eins~FR:un~ES:uno~NL:een~',
  TWO:'~EN:two~DE:zwei~FR:deux~ES:dos~NL:twee~',
  THREE:'~EN:three~DE:drei~FR:trois~ES:tres~NL:drie~',
  FOUR:'~EN:four~DE:vier~FR:quatre~ES:cuatro~NL:vier~',
  FIVE:'~EN:five~DE:fünf~FR:cinq~ES:cinco~NL:vijf~',
  SIX:'~EN:six~DE:sechs~FR:six~ES:seis~NL:zes~',
  SEVEN:'~EN:seven~DE:sieben~FR:sept~ES:siete~NL:zeven~',
  EIGHT:'~EN:eight~DE:acht~FR:huit~ES:ocho~NL:acht~',
  NINE:'~EN:nine~DE:neun~FR:neuf~ES:nueve~NL:negen~',
  TEN:'~EN:ten~DE:zehn~FR:dix~ES:diez~NL:tien~',

  // alphabetical
  API_ERROR:
    '~EN:api error'+
    '~DE:API-Fehler'+
    '~FR:erreur api'+
    '~ES:error de api'+
    '~NL:api fout~~',
  YES:
    '~EN:yes'+
    '~DE:ja'+
    '~FR:oui'+
    '~ES:sí'+
    '~NL:ja~~',
  NO:
    '~EN:no'+
    '~DE:nein'+
    '~FR:non'+
    '~ES:no'+
    '~NL:nee~~',

  // HTML response codes
  RESPONSE_100_NO_RESPONSE:
    '~EN:no response from server (100)'+
    '~DE:keine Antwort vom Server (100)'+
    '~FR:pas de réponse du serveur (100)'+
    '~ES:no hay respuesta del servidor (100)'+
    '~NL:geen reactie van de server (100)~~',
  RESPONSE_110_NO_RESPONSE:
    '~EN:no response from server (110)'+
    '~DE:keine Antwort vom Server (110)'+
    '~FR:pas de réponse du serveur (110)'+
    '~ES:no hay respuesta del servidor (110)'+
    '~NL:geen reactie van de server (110)~~',
  RESPONSE_404_NOT_FOUND:
    '~EN:page not found response from server (404)'+
    '~DE:Seite nicht gefunden Antwort vom Server (404)'+
    '~FR:page non trouvée réponse du serveur (404)'+
    '~ES:página no encontrada respuesta del servidor (404)'+
    '~NL:pagina niet gevonden reactie van de server (404)~~',
  RESPONSE_500_EXCEPTION:
    '~EN:exception response from server (500)'+
    '~DE:Ausnahmeantwort vom Server (500)'+
    '~FR:réponse d\'exception du serveur (500)'+
    '~ES:respuesta de excepción del servidor (500)'+
    '~NL:uitzonderingsreactie van de server (500)~~',
  RESPONSE_401_NOT_AUTHORIZED:
    '~EN:not authorized response from server (401)'+
    '~DE:nicht autorisierte Antwort vom Server (401)'+
    '~FR:réponse non autorisée du serveur (401)'+
    '~ES:no autorizado respuesta del servidor (401)'+
    '~NL:niet geautoriseerde reactie van de server (401)~~',
  RESPONSE_999_UNKNOWN_CODE:
    '~EN:unknown response from server (999)'+
    '~DE:unbekannte Antwort vom Server (999)'+
    '~FR:réponse inconnue du serveur (999)'+
    '~ES:respuesta desconocida del servidor (999)'+
    '~NL:onbekende reactie van de server (999)~~',
}
//eof