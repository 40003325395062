import {defineStore} from 'pinia'
import {language_text} from "@/assets/js/tools/Language";

export const useGlobalStore=defineStore({
  id:'global',
  state:()=>({
    // info
    program_name:'Bridge Course Helper',
    program_version:'Version 30/2023-11.002',
    copyright:'(c) 2023 Rob Gelders - Spirit Solutions b.v.',
    author:'Rob Gelders',
    // routes
    homepage_route:'/',
    //api_url:'http://bb.local/b/',
    api_url:'https://bb.spi.nl/b/',
    // setup
    need_login:false, // true if login is needed
    language:'NL',
    locale:'nl-NL',
    test_mode:true,
    languages:['NL','EN'],
    // pre login data
    company_name:'',
    company_tag:'',
    password_cfg:'',
    password_code:'',
    password_filename:'',
    soap_url:'',
    navision_version:'',
    // login variables
    token_api:'6472638754327856874365876435643987675983478657430',
    // state variables
    current_route:'/',
    step:0,
    // texts
    texts:{},
    texts_loaded:false,
  }),
  getters:{
    getToken:()=>{
      return this.token
    }
  },
  actions:{
    txt(tag,lang=''){
      // set default language
      if((lang===undefined)||(lang==='')){
        lang=this.language
      }
      // check this.texts empty
      if(!this.texts_loaded){
        this.loadTexts();
        this.texts_loaded=true
      }
      return language_text(this.texts,tag,lang)
    },
    txt_capitalize(tag){
      return this.txt(tag).toUpperCase()
    },
    txt_lower(tag){
      return this.txt(tag).toLowerCase()
    },
    txt_upper(tag){
      return this.txt(tag).toUpperCase()
    },
    txt_first_upper(tag){
      const txt=this.txt(tag)
      return txt.charAt(0).toUpperCase()+txt.slice(1) // first letter to upper case
    },
    loadTexts(){
      const import1=require('@/assets/js/texts')
      const import2=require('@/assets/js/standard_texts')
      const import3=require('@/assets/js/message_texts')
      this.texts=import1.texts
      Object.assign(this.texts,import2.standard_texts)
      Object.assign(this.texts,import3.message_texts)
      //console.log('loadTexts',this.texts)
    },
    setToken(token){
      this.token=token
      //console.log('setToken: token='+token)
    }
  }
})
//eof