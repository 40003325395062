// standard texts
export const texts={
  // ==============
  // === TITLES ===
  // ==============
  TITLE_ABOUT_PAGE:
    '~EN:About'+
    '~DE:Impressum'+
    '~FR:À propos de'+
    '~ES:Acerca de'+
    '~NL:Over~~',
  TITLE_COUNT_HCP:
    '~EN:Count Points (HCP)'+
    '~DE:Punkte zählen (HCP)'+
    '~FR:Compter les points (HCP)'+
    '~ES:Contar puntos (HCP)'+
    '~NL:Punten tellen (HCP)~~',
  TITLE_MENU_MAIN:
    '~EN:Main Menu'+
    '~DE:Hauptmenü'+
    '~FR:Menu principal'+
    '~ES:Menú principal'+
    '~NL:Hoofdmenu~~',
  TITLE_NOT_FOUND_PAGE:
    '~EN:Page not found'+
    '~DE:Seite nicht gefunden'+
    '~FR:Page non trouvée'+
    '~ES:Página no encontrada'+
    '~NL:Pagina niet gevonden~~',
  TITLE_OPENING_BID:
    '~EN:Opening bid'+
    '~DE:Eröffnungsgebot'+
    '~FR:Offre d\'ouverture'+
    '~ES:Apertura'+
    '~NL:Openingsbod~~',
  // ===================
  // === CARD TERMS  ===
  // ===================
  HCP:
    'HCP',
  NEW_HAND:
    '~EN:New hand'+
    '~DE:Neue Hand'+
    '~FR:Nouvelle main'+
    '~ES:Nueva mano'+
    '~NL:Nieuwe hand~~',
  NO_TRUMP_SHORT:
    '~EN:NT'+
    '~DE:SA'+
    '~FR:SA'+
    '~ES:SA'+
    '~NL:SA~~',
  OPENING_BID:
    '~EN:Opening bid'+
    '~DE:Eröffnungsgebot'+
    '~FR:Offre d\'ouverture'+
    '~ES:Apertura'+
    '~NL:Openingsbod~~',
  OPENING_BID_RULE:
    '~EN:Rule'+
    '~DE:Regel'+
    '~FR:Règle'+
    '~ES:Regla'+
    '~NL:Regel~~',
  PLAYER_N:
    '~EN:North'+
    '~DE:Nord'+
    '~FR:Nord'+
    '~ES:Norte'+
    '~NL:Noord~~',
  PLAYER_E:
    '~EN:East'+
    '~DE:Ost'+
    '~FR:Est'+
    '~ES:Este'+
    '~NL:Oost~~',
  PLAYER_S:
    '~EN:South'+
    '~DE:Süd'+
    '~FR:Sud'+
    '~ES:Sur'+
    '~NL:Zuid~~',
  PLAYER_W:
    '~EN:West'+
    '~DE:West'+
    '~FR:Ouest'+
    '~ES:Oeste'+
    '~NL:West~~',
  RANDOM_HAND:
    '~EN:Random hand'+
    '~DE:Zufällige Hand'+
    '~FR:Main aléatoire'+
    '~ES:Mano aleatoria'+
    '~NL:Willekeurige hand~~',
  SPREAD:
    '~EN:Spread'+
    '~DE:Verbreitung'+
    '~FR:Propagation'+
    '~ES:Propagación'+
    '~NL:Verdeling~~',
  YOUR_ANSWER:
    '~EN:Answer'+
    '~DE:Antwort'+
    '~FR:Réponse'+
    '~ES:Respuesta'+
    '~NL:Antwoord~~',
  // =================
  // === BID RULES ===
  // =================
  BID_RULE_PASS_BELOW_12:
    '~EN:Pass below 12'+
    '~DE:Pass unter 12'+
    '~FR:Passer en dessous de 12'+
    '~ES:Pase por debajo de 12'+
    '~NL:Pas onder de 12~~',
  BID_RULE_NT_15_17_BALANCED:
    '~EN:1NT: 15-17 balanced'+
    '~DE:1SA: 15-17 ausgewogen'+
    '~FR:1SA: 15-17 équilibré'+
    '~ES:1SA: 15-17 equilibrado'+
    '~NL:1SA: 15-17 gebalanceerd~~',
  BID_RULE_LONGEST_COLOR:
    '~EN:Longest color'+
    '~DE:Längste Farbe'+
    '~FR:Plus longue couleur'+
    '~ES:Color más largo'+
    '~NL:Langste kleur~~',
  BID_RULE_LOWEST_4_HAND:
    '~EN:Lowest 4 hand'+
    '~DE:Niedrigste 4 Hand'+
    '~FR:4 mains les plus basses'+
    '~ES:4 manos más bajas'+
    '~NL:Laagste 4 hand~~',
  BID_RULE_HIGHEST_5_HAND:
    '~EN:Highest 5 hand'+
    '~DE:Höchste 5 Hand'+
    '~FR:5 mains les plus hautes'+
    '~ES:5 manos más altas'+
    '~NL:Hoogste 5 hand~~',
  BID_RULE_HIGHEST_6_HAND:
    '~EN:Highest 6 hand'+
    '~DE:Höchste 6 Hand'+
    '~FR:6 mains les plus hautes'+
    '~ES:6 manos más altas'+
    '~NL:Hoogste 6 hand~~',
  BID_RULE_HIGH_5_BEATS_LOW_6:
    '~EN:High 5 beats low 6'+
    '~DE:Hoch 5 schlägt niedrig 6'+
    '~FR:5 haut bat 6 bas'+
    '~ES:5 altos vencen 6 bajos'+
    '~NL:Hoog 5 verslaat laag 6~~',
  BID_RULE_DEFAULT_ONE_CLUBS:
    '~EN:No other rule; default to 1 clubs'+
    '~DE:Keine andere Regel; Standard auf 1 Kreuz'+
    '~FR:Aucune autre règle; par défaut à 1 clubs'+
    '~ES:Ninguna otra regla; por defecto a 1 clubs'+
    '~NL:Geen andere regel; standaard naar 1 clubs~~',
  BID_RULE_1C_4_HEARTS_4_SPADES:
    '~EN:1NT: 4 hearts or spades'+
    '~DE:1SA: 4 Herzen oder Pik'+
    '~FR:1SA: 4 coeurs ou piques'+
    '~ES:1SA: 4 corazones o picas'+
    '~NL:1SA: 4 harten of schoppen~~',

  // ====================
  // === PAGE CONTENT ===
  // ====================
  NOT_FOUND_PAGE_INFO:
    '~EN:Unfortunately the page you requested could not be found, try another page.'+
    '~DE:Leider konnte die angeforderte Seite nicht gefunden werden, versuchen Sie eine andere Seite.'+
    '~FR:Malheureusement, la page que vous avez demandée n\'a pas pu être trouvée, essayez une autre page.'+
    '~ES:Lamentablemente no se pudo encontrar la página que solicitó, intente otra página.'+
    '~NL:Helaas kon de door u opgevraagde pagina niet worden gevonden, probeer een andere pagina.~~',
}
//eof