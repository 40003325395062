<!--Setup script-->
<script setup lang="js">

//import {ref} from 'vue'
import {useRouter} from "vue-router";
import {useGlobalStore} from "@/assets/js/store";

const router = useRouter()
const globals = useGlobalStore()

document.title = globals.program_name

</script>

<!--Template-->
<template>

  <main id="main_main">
    <router-view :key="router.currentRoute.value.path"></router-view>
  </main>

</template>

<!--Style-->
<style scoped>
</style>
